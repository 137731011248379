#details {
	.price {
		font-size: 2.5em;
		font-weight: bold;
		text-align: center;
		line-height: 1.2;
	}

	.badges:empty {
		display: none;
	}

	.focus {
		@include box;
	}

	.garage {
		@include box;
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.label {
			display: inline;
		}
	}

	.specs-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		margin-bottom: 8px;

		.value {
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.key {
			opacity: 0.8;
			margin-right: 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		> div {
			margin-bottom: $grid-gutter-width / 2;
		}

		.focus {
			display: none;
		}

		h2.h5 {
			margin-top: 2rem;
		}
	}

	@include media-breakpoint-up(lg) {
		$gap: $grid-gutter-width;
		overflow: hidden;
		margin-bottom: -$gap;

		.left,
		.right {
			margin-bottom: $gap;
		}

		.left {
			width: calc(66.666666% - #{$gap / 2});
		}

		.right {
			float: right;
			clear: right;
			width: calc(33.333333% - #{$gap / 2});
		}

		.actions {
			height: calc(2.5rem * 1.2);
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;

		.left,
		.right {
			margin-bottom: $grid-gutter-width / 2;
			order: 99;
		}

		.name {
			order: 1;
		}

		.price {
			order: 2;
		}

		.images {
			order: 3;
		}

		.actions {
			order: 4;
		}

		.specs {
			order: 6;
		}

		.description {
			order: 8;
		}

		.equipment {
			order: 9;
		}

		.badges {
			order: 2;
			text-align: center;
		}
	}

	&.sold {
		> *:not(aside):not(.price) {
			opacity: .7;
			filter: grayscale(1);
		}
	}
}
