.filter-form {
	display: flex;

	.btn-link {
		padding-left: 0;
		padding-right: 0;
	}
}

.filter-item {
	margin-right: .5rem;
}

.filter-item-content {
	background: #fff;
	padding: 1rem;
	padding-bottom: .5rem;
}

.filter-item-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: .5rem;
}

.filter-item-query {
	margin-bottom: 1rem;
}

.filter-list-item {
	padding: 2px 0;

	.custom-control-label {
		display: block;
	}
}

.filter-group-name {
	font-weight: bold;
}

.filter-group {
	margin-bottom: 0.5rem;
}

.filter-mobile-close {
	display: none;
}

.filter-item-info {
	&:before {
		content: "\00B7";
		display: inline;
		padding: 0 .5rem;
	}

	@include media-breakpoint-down(md) {
		span {
			background: $primary;
			color: #fff;
			border-radius: $border-radius;
			padding: 2px 5px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.filter-item-content {
		z-index: 2;
		position: absolute;
		border: 1px solid #eee;
		box-shadow: $box-shadow-sm;
		margin-top: 5px;
		width: 300px;
	}

	.filter-item-trigger {
		display: flex;
		@include button-variant(#fff, $secondary);
		border-color: $border-color !important;
	}

	.filter-item-scroll {
		overflow-y: auto;
		max-height: 300px;
		margin:0 -1rem;
		padding-left: 1rem;
	}

	.filter-heading {
		display: none;
	}

}

@include media-breakpoint-down(md) {
	.filter-form {
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background: #fff;
		flex-direction: column;
		z-index: 99;
		overflow-y: auto;
		padding: 1rem;
		margin-bottom: 1rem;
	}

	.filter-reset {
		margin-top: auto;
	}

	.filter-item {
		margin-right: 0;
		border: 1px solid $border-color;
		margin-bottom: -1px;
		order: 2;
		@include border-top-radius($border-radius);
		min-height: 35px;
		overflow: hidden;

		& ~ & {
			border-radius: 0;
		}

		&:last-of-type {
			@include border-bottom-radius($border-radius);
		}


		.filter-item-trigger {
			width: 100%;
			text-align: left;
			display: flex;

			&:after {
				margin-left: auto;
				content: "\f054";
				font-weight: 900;
				font-family: "Font Awesome 5 Free";
			}
		}

		&.open {
			.filter-item-trigger {
				&:after {
					content: "\f077";
				}
			}
		}
	}

	.filter-item-content {
		display: flex;
		flex-direction: column;
		max-height: calc(100% - 33px);
		position: relative;
	}

	.filter-item-scroll {
		overflow-y: auto;
		max-height: 100%;
	}

	.filter-buttons {
		display: flex;
		order: 1;
	}

	.filter-heading {
		order: 2;
		display: flex;
		justify-content: space-between;
		font-size: $h3-font-size;
		margin-bottom: 1rem;
	}

	.filter-mobile-close {
		display: block;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1rem;
		background: #fff;
	}
}
