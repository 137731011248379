.bounce-enter-active {
	animation: bounce-in 0.5s;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}