$enable-responsive-font-sizes: true;

$primary: #f47721;
$dark: #272727;
$warning: #ffd800;

$font-family-sans-serif: "Asap Condensed", sans-serif;

$headings-margin-bottom: 1rem;

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 2rem;

$navbar-nav-link-padding-x: .8rem;
$navbar-light-active-color: #000;
$navbar-light-color: $dark;

$navbar-toggler-padding-x: 0;

$breadcrumb-font-size: .9rem;
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: "\f105";

$border-color: #ebebeb;
$list-group-border-color: $border-color;
$list-group-item-padding-y: .5rem;
$list-group-item-padding-x: 1rem;

$grid-gutter-width: 20px;