@import "variables";
@import "~bootstrap";
@import "mixins";
@import "transitions";
@import "filter";
@import "list";
@import "details";
@import "features";
@import "products";
@import "garages";

:root {
	font-size: 14px;
}

body {
	overflow-y: scroll;
	background-color: $light;
	word-spacing: 2px;

	&.error,
	&.home.search {
		#main {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 60vh;
		}
	}

	@include media-breakpoint-up(lg) {
		&.scrolled {
			#header {
				border-bottom: $border-width solid $border-color;

				.navbar {
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
		}
	}
}

body.search {
	#main {
		.cta {
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}
}

#header {
	background: $white;
	position: sticky;
	top: 0;
	z-index: 999;

	.navbar {
		transition: padding 0.2s ease-in-out;

		@include media-breakpoint-down(md) {
			.dropdown-menu {
				display: block;
			}
		}

		.dropdown:hover > .dropdown-menu {
			margin-top: 0;
			display: block;
		}
	}
}

#navbar {
	font-weight: bold;
	font-size: 1.2em;

	.active {
		a {
			color: $primary;
		}
	}
}

.navbar-toggler {
	border: none;
	color: red;

	&:focus {
		outline: none;
	}

	.navbar-toggler-icon {
		width: 30px;
		height: 30px;
	}
}

#logo {
	width: 114px;
	height: 42px;
	display: block;
	background-image: url("../images/logo.png");
	background-repeat: no-repeat;
	background-size: contain;
	font-size: 0;
	margin-right: 2rem;

	@include media-breakpoint-down(md) {
		margin-right: 1rem;
	}
}

.search-form {
	.btn {
		border-color: $input-border-color;
		border-left: 0;
	}

	.form-control {
		border-right: 0;
		border-color: $input-border-color;

		&:focus {
			box-shadow: none;
		}
	}

	@include media-breakpoint-up(lg) {
		order: 99;
		width: 335px;
	}
}

.pagination {
	justify-content: center;

	.page-link {
		color: $text-muted;
	}

	.disabled {
		.page-link {
			opacity: 0.5;
		}
	}

	.page-spacer {
		position: relative;
		display: block;
		padding: $pagination-padding-y $pagination-padding-x;
		line-height: $pagination-line-height;
		color: $text-muted;
	}

	@include media-breakpoint-down(sm) {
		.page-extras {
			display: none;
		}
	}
}

.garage {
	a {
		color: inherit;
	}

	.fas {
		margin-right: 5px;
	}
}

.box {
	@include box;
}

.breadcrumbs {
	.breadcrumb-item {
		&:before {
			font-family: "Font Awesome 5 Free";
			font-weight: 700;
			padding: 0 0.5rem;
			vertical-align: middle;
		}

		&:first-of-type a {
			font-size: 0;
			text-decoration: none;

			&:before {
				font-family: "Font Awesome 5 Free";
				font-size: $breadcrumb-font-size;
				font-weight: 700;
				content: "\f015";
			}
		}

		a {
			color: inherit;
		}
	}

	.breadcrumbs-next {
		select {
			padding: 0;
			color: $breadcrumb-divider-color;
			background: none;
			border: none;
			cursor: pointer;

			option {
				color: $body-color;
			}
		}
	}
}

#heading {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include media-breakpoint-down(lg) {
		font-size: 2em;
	}

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.2em;
	}
}

#footer {
	padding-top: $grid-gutter-width * 2;
	background-color: $dark;
	color: #fff;

	.heading {
		text-transform: uppercase;
		font-weight: bold;
		margin: 0;

		span {
			color: $primary;
		}
	}

	.sub-heading {
		color: #8c8c8c;
		font-weight: bold;
	}

	ul {
		@include list-unstyled();
		margin-bottom: 0;

		a {
			color: inherit;
			padding: 5px 0;
			display: inline-block;
			text-decoration: none;

			&:after {
				font-family: "Font Awesome 5 Free";
				font-weight: bold;
				content: "\f0da";
				padding-left: 0.5rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.col:not(:last-of-type) {
			margin-bottom: $grid-gutter-width * 2;
		}
	}

	#credits {
		text-align: right;
		background-color: $primary;
		margin-top: $grid-gutter-width * 2;
		padding: $grid-gutter-width 0;
	}
}

.pagination {
	.page-item {
		&.active {
			.page-link {
				font-weight: bold;
				border-color: $gray-300;
				background-color: $white;
				color: #000;
			}
		}
	}
}

.jumbotron {
	margin-bottom: 0;
	background: no-repeat center center / cover;

	body:not(.home) & h1 {
		text-shadow: 0px 3px 5px rgba(0, 0, 0, .4);
	}

	.box {
		padding: $grid-gutter-width;
		box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
	}
}

.section {
	padding: $grid-gutter-width * 2 0;

	&:nth-child(odd) {
		background-color: #fff;
	}

	@include media-breakpoint-up(md) {
		.focus {
			margin-right: -9px;
		}
	}
}

.grecaptcha-badge {
	visibility: hidden;
}


.double-border {
	$border-size: 5px;

	display: block;
	border-top: $border-size solid $primary;

	&:before {
		content: "";
		display: block;
		height: $border-size;
		background: $secondary;
		margin-top: 2px;
	}

	&.double-border-lg {
		$border-size: 18px;
		border-top-width: $border-size;

		&:before {
			height: $border-size;
			margin-top: 3px;
		}
	}
}

#st-1 {
	z-index: auto !important;

	.st-btn {
		display: inline-block !important;
	}
}

#main .garage {
	position: relative;

	a:not(.stretched-link) {
		position: relative;
		z-index: 2;
	}
}

ins[data-ad-status="unfilled"] {
	display: none !important;
}

$extra-colors: ( "orange": #F37720, "green": #5EAE11, "blue": #2D6888, "grey": #6C757D, "pink": #ed358d );

@each $color, $value in $extra-colors {
	.text-#{$color} {
		color: $value;
	}

	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}


.popular-categories {
	.popular-category {
		position: relative;
		z-index: 1;
		cursor: pointer;
		z-index: 2;

		& + div {
			margin-top: -8px;
			position: absolute;
			left: $grid-gutter-width / 2;
			right: $grid-gutter-width / 2;
			z-index: 3;
		}

		&:after {
			content: "";
			background: $primary;
			height: 5px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 8px;
			z-index: -1;
		}
	}
}
