.filter {
	margin-bottom: 1em;
}

.list {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1em;
	}

	.list-actions {
		display: flex;

		.list-actions-item {
			margin-left: 1em;
		}
	}

	@include media-breakpoint-down(sm) {
		.top {
			flex-wrap: wrap;

			.list-actions {
				justify-content: space-between;
			}

			.list-actions-item {
				margin: 0;
			}

			.count {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
				margin-bottom: 1em;
			}

			.list-actions {
				width: 100%;
			}
		}
	}

	.badges {
		position: absolute;

		.badge {
			font-size: .9em;
			border-radius: 0;
		}
	}
}

.list.list-view {
	.list-item {
		@include make-row(0);
		@include box;
		padding: 0;
		margin-bottom: 1rem;
		color: inherit !important;
		text-decoration: none !important;

		.image {
			@include make-col-ready(0);

			> div {
				padding-bottom: 75%;
				background-position: center center;
				background-size: cover;
			}
		}

		.list-details {
			@include make-col-ready();
			margin: $grid-gutter-width / 2 0;
			display: flex;
			flex-wrap: wrap;

			.name {
				font-size: 1.2rem;
				font-weight: bold;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
			}

			.description {
				color: $text-muted;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.thumbnails {
				display: flex;
				width: 100%;
				margin-top: auto;
				overflow: hidden;
			}

			.thumbnail {
				width: 50px;
				flex: 0 0 auto;
				margin-right: 2px;
				opacity: 0.5;
				transition: opacity 0.2s;

				&:hover {
					opacity: 1;
				}

				> div {
					padding-bottom: 75%;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
		}

		.info {
			@include make-col-ready();
			margin: $grid-gutter-width / 2 0;
			align-items: center;

			.price {
				font-size: 1.2rem;
				font-weight: bold;
			}

			.list-item-actions {
				text-align: right;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.list-item {
			.list-details {
				margin-bottom: 0;

				.description {
					margin-top: 1em;
					width: 100%;
				}
			}

			.image {
				> div {
					padding-bottom: 56.25%;
				}
			}

			.info {
				display: flex;
				justify-content: space-between;

				.list-item-actions {
					display: flex;
					justify-content: space-between;

					> div {
						margin-left: 1em;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.list-item {
			.list-details {
				.thumbnails .thumbnail {
					display: none;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.list-item {
			.image {
				@include make-col(4);
			}

			.list-details {
				@include make-col(5);
			}

			.info {
				@include make-col(3);
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				.list-item-actions {
					margin-top: auto;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.list-item {
			.image {
				@include make-col(2);
			}

			.list-details {
				@include make-col(8);

				.name {
					width: 50%;
				}

				.specs {
					display: flex;
					width: 50%;
				}

				.specs-item {
					width: 33.3%;
					text-align: right;
				}
			}

			.info {
				@include make-col(2);
			}
		}
	}

	.badges {
		top: -3px;
	}
}

.list.gallery-view {
	.list-item {
		margin-bottom: $grid-gutter-width;

		* {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		a {
			color: inherit;
			text-decoration: none;
		}

		.content {
			background-position: center center;
			background-size: cover;
			margin-bottom: 6px;
		}

		.price {
			position: absolute;
			font-weight: bold;
			padding: 6px;
			background: $warning;
			right: 0;
			bottom: 0;
		}

		.name {
			font-weight: bold;
			text-align: center;
		}

		.attributes {
			margin-top: 6px;
			display: flex;

			> div {
				flex: 1;
				border: 1px solid $dark;
				border-radius: $btn-border-radius;
				padding: $btn-padding-y $btn-padding-x;
				text-align: center;

				&:nth-child(odd) {
					margin-right: 3px;
				}

				&:nth-child(even) {
					margin-left: 3px;
				}
			}
		}
	}
}
