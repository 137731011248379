#garages-details {
	.logo {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:not(.empty) {
			@include box;
		}

		img {
			max-height: 100%;
		}
	}

	.services {
		@include media-breakpoint-up(md) {
			columns: 2;
		}

		@include media-breakpoint-up(lg) {
			columns: 3;
		}

		ul {
			display: inline-block;
			width: 100%;
		}
	}

	.socials {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		a {
			text-decoration: none;
		}

		.fa-facebook-square {
			color: #4267b2;
		}

		.fa-instagram-square {
			color: transparent;
			background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
			background-clip: text;
		}

		.fa-linkedin {
			color: #2867b2;
		}

		.fa-pinterest-square {
			color: #bd081c;
		}

		.fa-twitter-square {
			color: #1da1f2;
		}

		.fa-youtube-square {
			color: #ff0000;
		}
	}

	$gap: $grid-gutter-width;


	@include media-breakpoint-up(md) {
		overflow: hidden;
		margin-bottom: -$gap;

		.left, .right {
			margin-bottom: $gap;
		}

		.left {
			width: calc(60% - #{$gap / 2});
		}

		.right {
			float: right;
			clear: right;
			width: calc(40% - #{$gap / 2});
		}

		.logo {
			height: calc(5.5rem * 1.2);
		}
	}

	@include media-breakpoint-up(lg) {
		.left {
			width: calc(66.666666% - #{$gap / 2});
		}

		.right {
			width: calc(33.333333% - #{$gap / 2});
		}
	}

	@include media-breakpoint-up(xl) {
		.logo {
			height: calc(6rem * 1.2);
		}
	}

	@include media-breakpoint-down(sm) {
		display: flex;
		flex-direction: column;

		.left, .right {
			margin-bottom: $grid-gutter-width / 2;
			order: 99;
		}

		.name {
			order: 1;
		}

		.logo {
			order: 2;
		}

		.images {
			order: 3;
		}

		.description {
			order: 4;
		}

		h2.h5 {
			margin-top: calc(2rem - #{$grid-gutter-width / 2});
		}

		.logo.empty {
			display: none;
		}
	}
}
