





































































































































.products-category-groups {
	.group,
	.subgroup {
		cursor: pointer;
	}

	$primary: #f47721;
	$gray-600: #6c757d;

	svg {
		#Fill * {
			fill: $gray-600;
			fill-opacity: 0.2;
			opacity: 1;
		}

		#Rød_over * {
			fill: $primary;
			fill-opacity: 1;
			opacity: 1;
		}

		#Outline * {
			fill: $gray-600;
			opacity: 1;
			fill-opacity: 1;
			stroke: none;
		}

		#Rød_under * {
			fill: $primary;
			fill-opacity: 1;
			opacity: 1;
		}
	}
}
