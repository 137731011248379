﻿.favorite {
	color: $danger;
}

.compare {
	color: $info;
}

.share {
	color: #4267b2;
}

.favorite, .compare, .share {
	cursor: pointer;

	@include media-breakpoint-down(sm) {
		.label {
			display: none;
		}
	}
}