.products-numberplate {
	$border-radius: 5px;
	$label-width: 22px;

	.numberplate {
		border: 3px solid #fe0000;
		border-radius: $border-radius;
		padding-left: $label-width;
		position: relative;
		background-color: #fff;

		&:before {
			content: "DK";
			text-align: center;
			color: #fff;
			font-size: 10px;
			padding-top: 22px;
			width: $label-width;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: #2874b2;
			border-radius: 2px 0 0 2px;
		}

		&:after {
			content: "";
			background: url("../images/numberplate-stars.svg") no-repeat top center / contain;
			position: absolute;
			left: 2px;
			top: 4px;
			width: $label-width - 4px;
			bottom: 0;
		}
	}

	.form-control {
		border: 0;
		box-shadow: none;
	}
}

#products-details {
	.price {
		font-size: 2.5em;
		font-weight: bold;
		text-align: center;
		line-height: 1.2;
	}

	.images {
		text-align: center;
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.label {
			display: inline;
		}
	}

	.attributes {
		@include box;
	}

	.garage {
		@include box;
	}

	.vehicles {
		.ellipsis {
			position: relative;

			&:before {
				content: "&nbsp;";
				visibility: hidden;
			}

			a {
				position: absolute;
				left: 0;
				right: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		[data-toggle=collapse][data-target="#vehicles"] {
			&[aria-expanded=true] {
				span {
					display: none;
				}

				[hidden] {
					display: block !important;
				}
			}
		}
	}

	.specs-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		.value {
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.key {
			opacity: .8;
			margin-right: 10px;
		}
	}

	$gap: $grid-gutter-width;

	@include media-breakpoint-up(lg) {
		overflow: hidden;
		margin-bottom: -$gap;

		.left, .right {
			margin-bottom: $gap;
		}

		.left {
			width: calc(66.666666% - #{$gap / 2});
		}

		.right {
			float: right;
			clear: right;
			width: calc(33.333333% - #{$gap / 2});
		}

		.actions {
			height: calc(2.5rem * 1.2);
		}

		.specs .specs-item:nth-last-child(2) {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		display: flex;
		flex-direction: column;

		.left, .right {
			margin-bottom: $grid-gutter-width / 2;
			order: 99;
		}

		.name {
			order: 1;
		}

		.price {
			order: 2;
		}

		.images {
			order: 3;
		}

		.related {
			order: 100;
		}

		h2.h5 {
			margin-top: calc(2rem - #{$grid-gutter-width / 2});
		}
	}
}

#popular-categories {
	margin-bottom: -$grid-gutter-width;

	.popular-category {
		padding-bottom: 56.25%;
		display: block;
		position: relative;
		background-color: $dark;
		background-repeat: no-repeat;
		background-position: right -25px bottom -25px;
		background-size: contain;
		color: #fff;
		margin-bottom: $grid-gutter-width;

		.content {
			padding: $grid-gutter-width;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		.name {
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1.4em;
		}

		.description {
			max-width: 50%;
		}
	}

	> .col {
		&:nth-child(odd) .popular-category {
			background-color: $primary;
		}
	}
}
